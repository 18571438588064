const OPERATION_TYPES = {
  UpdateByAllGoogleSheet: "UpdateByAllGoogleSheet",
  CreateGoogleSheet: "CreateGoogleSheet",
  UpdateActivePromotionSheetCommand: "UpdateActivePromotionSheetCommand",
  UpdateUpcomingPromotionSheetCommand: "UpdateUpcomingPromotionSheetCommand",
  UpdateGoogleSheetActivePromotion: "UpdateGoogleSheetActivePromotion",
  UpdateGoogleSheetUpcomingPromotion: "UpdateGoogleSheetUpcomingPromotion",
  
  CreateInventorySheetCommand: "CreateInventorySheetCommand",
};

export default OPERATION_TYPES;