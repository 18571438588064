import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";

import api from "../../../../../../../api";

import {
  OrderStatus,
  salesman,
  superadmin,
} from "../../../../../../../constants/defaults";

import Modal from "../../../../../../../components/common/v2/modal";
import Button from "../../../../../../../components/common/v2/button";
import moment from "moment";
import { toast } from "react-toastify/dist";
import Loader from "../../../../../../../components/Loader";

const ModalEditOrder = ({ data, userScope }) => {
  const refForm = useRef();
  const { order, promotion: _promotion } = data || {};
  const [promotion, setPromotion] = useState(() => _promotion || {});

  const onSubmit = useCallback(async () => {
    const PromotionId = refForm.current["PromotionId"]?.value;
    const ReprocessBlending = refForm.current["ReprocessBlending"]?.value;
    const Quantity = refForm.current["Quantity"]?.value;
    const OfferPrice = refForm.current["OfferPrice"]?.value;
    const ProcessingStartOnUtc = refForm.current["ProcessingStartOnUtc"]?.value;
    const ProcessingEndOnUtc = refForm.current["ProcessingEndOnUtc"]?.value;
    const DontCreateInvoice = refForm.current["DontCreateInvoice"]?.checked;

    const body = {
      PromotionId,
      ReprocessBlending: !!PromotionId,
      Quantity,
      OfferPrice: OfferPrice ? parseFloat(OfferPrice) : undefined,
      ProcessingStartOnUtc,
      ProcessingEndOnUtc,
      DontCreateInvoice,
    };

    await api.orders.update(order.Id, body);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [order.Id]);

  const onFetchPromotion = useCallback(async () => {
    try {
      setPromotion({ loading: true });

      const promotionId = refForm.current["PromotionId"]?.value;

      const result = await api.campaigns.getCampaignFromManagement(
        promotionId,
        false
      );

      setPromotion(result);
    } catch (error) {
      toast.error(
        <span>
          {error?.response?.data?.message ||
            error?.message ||
            "Unknown message"}
        </span>
      );
    } finally {
      setPromotion((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  if (order.IsBilled) {
    return null;
  }

  if (![salesman, superadmin].includes(userScope)) {
    return null;
  }

  return (
    <Modal
      accessibility={{
        submit: {
          disabled: order.IsBilled,
          onClick: onSubmit,
          successMessage: "The order has been updated, successfully!",
          title: "Save",
        },
      }}
      trigger={{ theme: "primary", title: "Edit order" }}
      title="Edit order details"
    >
      <form ref={refForm}>
        <table className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive">
          <tbody>
            <tr>
              <td>
                <span>
                  <b>Offer Quantity:</b>
                </span>
              </td>
              <td>
                <input
                  name="Quantity"
                  className="form-control"
                  disabled={order.IsBilled}
                  defaultValue={order.Offer?.OfferQuantity}
                  min={Math.max(
                    Math.max(order.DeliveredQuantity, order.CompletedCount),
                    1
                  )}
                  type="number"
                />
              </td>
            </tr>
            <tr className="border-bottom">
              <td>
                <span>
                  <b>Offer Price (€):</b>
                </span>
              </td>
              <td>
                <input
                  name="OfferPrice"
                  className="form-control"
                  disabled={order.IsBilled}
                  defaultValue={order.Offer?.OfferPrice}
                  min={0}
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <b>Promotion:</b>
                </span>
              </td>
              <td>
                <div className="d-flex gap-2">
                  <input
                    name="PromotionId"
                    defaultValue={promotion?.Id}
                    disabled={
                      promotion?.loading ||
                      [
                        OrderStatus.Completed,
                        OrderStatus.Closed,
                        OrderStatus.Cancelled,
                      ].includes(order.Status)
                    }
                    type="text"
                  />
                  <Button
                    disabled={
                      promotion?.loading ||
                      [
                        OrderStatus.Completed,
                        OrderStatus.Closed,
                        OrderStatus.Cancelled,
                      ].includes(order.Status)
                    }
                    onClick={onFetchPromotion}
                    size="default"
                    title="Get Promotion Data"
                  />
                </div>
              </td>
            </tr>
            <tr className="border-bottom">
              <td colSpan={2} className="position-relative">
                <Loader hidden={!promotion?.loading} />
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex gap-2">
                    <span>{promotion?.Product?.Name}</span>
                    <span>-</span>
                    <span>{promotion?.Product?.Region}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span>
                      <b>Tier 1: </b>
                      <span>€{promotion?.Tier1Price}</span>
                    </span>
                    <span>
                      <b>Tier 2: </b>
                      <span>€{promotion?.Tier2Price}</span>
                    </span>
                    <span>
                      <b>Tier 3: </b>
                      <span>€{promotion?.Tier3Price}</span>
                    </span>
                    <span>
                      <b>Tier 4: </b>
                      <span>€{promotion?.Tier4Price}</span>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <b>
                    Process Start Date<sup>UTC</sup>
                  </b>
                </span>
              </td>
              <td>
                <input
                  name="ProcessingStartOnUtc"
                  className="form-control"
                  defaultValue={
                    order.ProcessingStartOnUtc
                      ? moment
                          .utc(order.ProcessingStartOnUtc)
                          .format("YYYY-MM-DDTHH:mm:ss")
                      : undefined
                  }
                  disabled={[
                    OrderStatus.Completed,
                    OrderStatus.Closed,
                    OrderStatus.Cancelled,
                  ].includes(order.Status)}
                  type="datetime-local"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <b>
                    Process End Date<sup>UTC</sup>
                  </b>
                </span>
              </td>
              <td>
                <input
                  name="ProcessingEndOnUtc"
                  className="form-control"
                  defaultValue={
                    order.ProcessingEndOnUtc
                      ? moment
                          .utc(order.ProcessingEndOnUtc)
                          .format("YYYY-MM-DDTHH:mm:ss")
                      : undefined
                  }
                  disabled={[
                    OrderStatus.Completed,
                    OrderStatus.Closed,
                    OrderStatus.Cancelled,
                  ].includes(order.Status)}
                  type="datetime-local"
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <b>Don't Create Invoice</b>
                </span>
              </td>
              <td>
                <input
                  name="DontCreateInvoice"
                  className="form-check-input"
                  defaultChecked={order.DontCreateInvoice}
                  disabled={order.IsBilled}
                  type="checkbox"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </Modal>
  );
};

export default ModalEditOrder;
