import React from "react";

const BundleProduct = ({ promotion }) => {
  if (!promotion?.Product?.IsBundle) {
    return <div>This is not the bundle product</div>;
  }
  return (
    <div className="d-flex flex-column gap-2">
      <div className="overflow-auto" style={{ maxHeight: 300 }}>
        <div>
          <p
            className="mt-3 d-flex flex-column gap-2"
            style={{ fontSize: 12, opacity: 0.8 }}
          >
            <div>
              {promotion.Product.BundleProducts.map((item) => (
                <p>
                  <span>
                    <b>{item.ChildName}</b>
                  </span>
                  <br></br>
                  <span>
                    <b>Sku:</b>
                  </span>{" "}
                  <span>{item.SkuId}</span>
                </p>
              ))}
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BundleProduct;
