import React, { useCallback, useEffect, useState } from "react";

import { OrderStatus } from "../../../../../../../constants/defaults";

import Modal from "../../../../../../../components/common/v2/modal";
import api from "../../../../../../../api";
import { toast } from "react-toastify";

const ModalGetBuffered = ({ data, userScope }) => {
  const { order } = data || {};

  const [stockInfo, setStockInfo] = useState(null);
  const [allocationInfo, setValues] = useState(null);

  const stockRefresh = async (orderNumber) => {
    const stockRow = await api.orders.getStockInfo(
      orderNumber.replaceAll(" ", "")
    );

    if (stockRow.Sku != null || stockRow.Quantity > 0) {
      setStockInfo(stockRow);
    }
  };

  const onSubmit = useCallback(async () => {
    const result = await api.orders.getStockInfo(
      order.OrderNumber.replaceAll(" ", "")
    );

    if (result.Quantity > 0 && result.Sku != null) {
      setStockInfo(result);
    }

    let remainingQuantity = order.Quantity - order.CompletedCount;
    let availableQuantity = result.Available;

    if (remainingQuantity <= 0) {
      toast.info("The keys are buffered!");
      return;
    }

    console.log("availableQuantity: " + availableQuantity);
    console.log("remainingQuantity: " + remainingQuantity);

    if (availableQuantity > 0 && remainingQuantity > 0) {
      await api.orders.getBuffered(order.Id, order.Quantity);
    } else {
      toast.info("There's already a buffer waiting.");
    }
  }, [order]);

  useEffect(() => {
    const clearState = () => {
      setValues(null);
      setStockInfo(null);
    };

    if (!order) return clearState;

    const fetchData = async () => {
      try {
        const result = await api.orders.allocateInfo(order.Product.ProductSku);
        setValues(result);
      } catch (error) {
        toast.error(
          <div>
            {error?.response?.data.message || error.message || "Unknown error"}
          </div>
        );
      }
    };
    fetchData();

    const fetchStockData = async () => {
      try {
        const result = await api.orders.getStockInfo(
          order.OrderNumber.replaceAll(" ", "")
        );

        if (result.Quantity > 0 && result.Sku != null) {
          setStockInfo(result);
        }
      } catch (error) {
        toast.error(
          <div>
            {error?.response?.data.message || error.message || "Unknown error"}
          </div>
        );
      }
    };
    fetchStockData();

    return clearState;
  }, [order]);

  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  if (order.IsBilled || order.CompletedCount == order.Quantity) {
    return null;
  }

  if (
    ![
      OrderStatus.Processing,
      OrderStatus.Paused,
      OrderStatus.Closed,
      OrderStatus.OutOfStock,
      OrderStatus.PromotionExpired,
    ].includes(order.Status)
  ) {
    return null;
  }

  return (
    <Modal
      accessibility={{
        submit: {
          onClick: onSubmit,
          successMessage: "Get the buffered keys.",
          title: "Get",
        },
      }}
      trigger={{ theme: "warning", title: "Get Keys From Allocation" }}
      title="You can complete the order from the buffer if you wish."
    >
      <div className="d-flex flex-column gap-2">
        <div>
          {allocationInfo ? (
            <div>
              <div className="d-flex">
                <span className="w-50">
                  <b>General Allocation Info For Product:</b>
                </span>
              </div>
              <div className="d-flex">
                <span className="w-50 cloudy-blue">
                  Max Allocated Quantity:
                </span>
                <span className="w-50">
                  {allocationInfo?.MaxAllocated ?? "-"}
                </span>
              </div>
              <div className="d-flex">
                <span className="w-50 cloudy-blue">Max Buffered Quantity:</span>
                <span className="w-50">
                  {allocationInfo?.MaxBuffered ?? "-"}
                </span>
              </div>
              <div className="d-flex">
                <span className="w-50 cloudy-blue">
                  Total Buffered for Product:
                </span>
                <span className="w-50">{allocationInfo?.Buffered ?? "-"}</span>
              </div>
            </div>
          ) : null}
        </div>

        <div className="d-flex">
          <span className="w-50">
            <b>Order Allocation Info</b>
          </span>
          <span className="w-50">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              onClick={() => {
                stockRefresh(order.OrderNumber);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
              </svg>
            </button>
          </span>
        </div>

        <div className="d-flex">
          <span className="w-50 cloudy-blue">Allocated:</span>
          <span className="w-50">{stockInfo?.Quantity ?? "-"}</span>
        </div>

        <div className="d-flex">
          <span className="w-50 cloudy-blue">Buffered:</span>
          <span className="w-50">{stockInfo?.Available ?? "-"}</span>
        </div>

        <div className="d-flex">
          <span className="w-50 cloudy-blue">Sold:</span>
          <span className="w-50">{stockInfo?.Sold ?? "-"}</span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalGetBuffered;
