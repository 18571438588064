import {
  PROMOTIONS_LIST_PARTNER_PAGE_LOADED,
  CHANGE_PRICE_DETAILS_BASKET,
  SELECT_PROMOTION,
  PROMOTION_DETAIL,
  MANAGEMENT_PROMOTION_PAGE_LOADED,
  MANAGEMENT_CREATE_PROMOTION_PRODUCT,
  MANAGEMENT_PROMOTION_UPDATE_FIELD,
  FILTER_LOADER,
  MANAGEMENT_SELECTED_REGION_FIELD,
  MANAGEMENT_SELECTED_MERKUR_REGION_FIELD,
  PROVIDER_PROMOTION,
} from "../constants/actionTypes";
import { fixTimezoneOffset } from "../functions/dateConverter";

const date = new Date();
const defaultState = {
  promotionSearchText: "",
  selectedPromotion: undefined,
  promotions_management: undefined,
  selectedProduct: undefined,
  searchby: "name",
  searchProductText: "",
  Section1: "1",
  Section2: "0",
  selectedPriceId: undefined,
  selectedRegionId: undefined,
  minQuantity: 1,
  maxQuantity: 1000,
  validTo: fixTimezoneOffset(
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      30,
      0,
      0
    ).setMinutes(30)
  ),
  validFrom: fixTimezoneOffset(new Date().setMinutes(30)),
  price: "0",
  oldPrice: "0",
  countries: [],
  ModalType: "Create",
  selectedPrice: undefined,
  customPrice: false,
  demandPrice: 0,
  units: 0,
  published: true,
  setPlatforms: [],
  setOS: [],
  tier1Price: undefined,
  tier2Price: undefined,
  tier3Price: undefined,
  filterIsLoading: false,
  tableIsLoading: false,
  selectedRegion: [],
  selectedMerkurRegion: undefined,
  changePercentage: [],
  upcomingPromotions: undefined,
  isProductAlreadyCreated: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PROMOTIONS_LIST_PARTNER_PAGE_LOADED:
      return {
        ...state,
        promotions_partner: action.payload,
        filterIsLoading: false,
      };
    case CHANGE_PRICE_DETAILS_BASKET:
      return {
        ...state,
        [action.key]: action.value,
      };
    case SELECT_PROMOTION:
      return {
        ...state,
        selectedPromotion: action.promotion,
      };
    case PROMOTION_DETAIL:
      return {
        ...state,
        promotionDetail: action.payload,
      };
    case MANAGEMENT_PROMOTION_PAGE_LOADED:
      return {
        ...state,
        promotions_management: action.payload,
        filterIsLoading: false,
      };
    case MANAGEMENT_CREATE_PROMOTION_PRODUCT:
      return {
        ...state,
        products: action.payload,
      };
    case MANAGEMENT_PROMOTION_UPDATE_FIELD:
      return {
        ...state,
        [action.key]: action.value,
      };
    case MANAGEMENT_SELECTED_REGION_FIELD:
      return {
        ...state,
        selectedRegion: state.selectedRegion.includes(action.value)
          ? state.selectedRegion.filter((item) => item !== action.value)
          : [...state.selectedRegion, action.value],
      };
    case MANAGEMENT_SELECTED_MERKUR_REGION_FIELD:
      return {
        ...state,
        selectedMerkurRegion: action.value,
      };
    case FILTER_LOADER:
      return {
        ...state,
        filterIsLoading: action.payload,
      };
    case PROVIDER_PROMOTION:
      return {
        ...state,
        upcomingPromotions: action.payload,
      };
    default:
      return state;
  }
};
