import React from "react";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import BlankPage from "../../../components/BlankPage";
import Content from "../../../components/containers/Content";
import { connect } from "react-redux";
import {
  PROVIDER_PROMOTION,
  COMPANIES_PAGE_LOADED,
  REDIRECT,
} from "../../../constants/actionTypes";
import Pager from "../../../components/Pager";
import base64 from "base-64";
import Filter from "../../../components/Filter";
import { defaultDateFormat, dateTypes } from "../../../constants/defaults";
import moment from "moment";
import Button from "../../../components/common/Button";
import services from "../../../api/index";
import { Endpoints } from "../../../constants/endpoints";
import { Icons } from "../../../constants/icons";
import { Tab, Tabs } from "react-bootstrap";
import MerModal from "../../../components/common/MerModal";
import GoogleSheetConfirm from "../provider-products/components/GoogleSheetConfirm";
import OPERATION_TYPES from '../../../constants/operationTypes';
const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    companies: state.companies,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  upcomingPromotionsOnLoad: (payload) => {
    dispatch({ type: PROVIDER_PROMOTION, payload });
  },
  companiesOnLoad: (payload) => {
    dispatch({ type: COMPANIES_PAGE_LOADED, payload });
  },
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

const PAGE_TITLE = "Provider Promotions";

const PROMOTION_TYPES = {
  ActivePromotions: "Active",
  UpcomingPromotions: "Upcoming"
}

class UpcomingProviderPromotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.match.params.filter
        ? JSON.parse(base64.decode(this.props.match.params.filter))
        : null,
      pageindex: 0,
      loading: false,
      selectedTab: PROMOTION_TYPES.UpcomingPromotions,
      showPriceMasterModal: false,
      showGenbaPromotionModal: false,
      selectedPromotionId: "",
    };
  }
  componentDidMount() {
    document.title = PAGE_TITLE;
    this.getProviderPromotions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  getProviderPromotions = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const {
        pageindex = this.state.pageindex,
        pagesize = this.props.pageSize,
        createdAtFrom,
        createdAtTo,
        providerIds,
        publisherIds,
        keywords,
        promotionType,
      } = params;

      const response = await services.campaigns.providerPromotions(
        pageindex,
        pagesize,
        createdAtFrom,
        createdAtTo,
        providerIds,
        publisherIds,
        keywords,
        promotionType || PROMOTION_TYPES.UpcomingPromotions
      );

      this.props.upcomingPromotionsOnLoad(Promise.resolve([response]));
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      if (err.response?.data?.message) {
        toast.error(<div>{err.response.data.message}</div>);
      }
    }
  };

  updateGoogleSheet = async () => {
    const { selectedPromotionId } = this.state;
    if (!selectedPromotionId) return;

    const updateFn =
      this.state.selectedTab === PROMOTION_TYPES.ActivePromotions
        ? services.googleSheet.updateActiveGoogleSheet
        : services.googleSheet.updateUpcomingGoogleSheet;

    try {
      await updateFn(selectedPromotionId);
      toast.success(<div>Google sheet has been updated!</div>);

      if (this.state.selectedTab === PROMOTION_TYPES.ActivePromotions) {
        this.setState({ showPriceMasterModal: false });
      } else {
        this.setState({
          showPriceMasterModal: false,
          selectedPromotionId: "",
        });
      }
    } catch (err) {
      if (err.response?.data?.message) {
        toast.error(<div>{err.response.data.message}</div>);
      }
    }
  };


  updateProductPriceUpdateWithActiveDiscountRate = async (publisherId) => {
    try {
      await services.campaigns.updateProductPriceUpdateWithActiveDiscountRate(
        publisherId
      );
      toast.success(<div>The Price Updated!</div>);
    } catch (err) {
      if (err.response?.data?.message) {
        toast.error(<div>{err.response.data.message}</div>);
      }
    }
  };

  genbaPromotion = async (promotionId) => {
    const genbaFn =
      this.state.selectedTab === PROMOTION_TYPES.ActivePromotions
        ? services.googleSheet.genbaActivePromotions
        : services.googleSheet.genbaUpcomingPromotions;

    try {
      await genbaFn(promotionId);
      toast.success(<div>Added to the Google Sheet!</div>);
    } catch (err) {
      if (err.response?.data?.message) {
        toast.error(<div>{err.response.data.message}</div>);
      }
    }
  };


  onFilter = (params = {}) => {
    const { filter, pageindex } = params;

    this.setState(
      {
        filter: filter ?? this.state.filter,
        pageindex: pageindex ?? 0,
      },
      () => {
        // Use the updated state right here
        this.getProviderPromotions({
          pageindex: this.state.pageindex,
          createdAtFrom: this.state.filter?.DateRange?.min,
          createdAtTo: this.state.filter?.DateRange?.max,
          providerIds: this.state.filter?.Providers?.join(","),
          publisherIds: this.state.filter?.Publishers?.join(","),
          keywords: this.state.filter?.Text,
          promotionType: this.state.selectedTab
        });
      }
    );
  };


  onChangePageIndex = (pageindex) => {
    const { filter, selectedTab } = this.state;
    this.setState({ loading: true, pageindex });
    this.getProviderPromotions({
      pageindex,
      createdAtFrom: filter?.DateRange?.min,
      createdAtTo: filter?.DateRange?.max,
      providerIds: filter?.Providers?.join(","),
      publisherIds: filter?.Publishers?.join(","),
      keywords: filter?.Text,
      promotionType: this.state.selectedTab
    });
  };

  setTabKey(key) {
    this.setState({ selectedTab: key }, () => {
      this.onFilter();
    });
  }

  render() {
    const { upcomingPromotions } = this.props.promotions;

    return (
      <Content pageTitle={PAGE_TITLE}>
        {upcomingPromotions == undefined ? (
          <Loader />
        ) : (
          <div>
            <div className="row">
              <div className="select-game-filter">
                <Filter
                  filter={this.state.filter}
                  isProviderAvailable
                  isPublisherAvailable
                  dateList={[
                    dateTypes.LAST2MONTH,
                    dateTypes.THISMONTH,
                    dateTypes.NEXT2MONTH,
                    dateTypes.THISYEAR,
                    dateTypes.ALLTIME
                  ]}
                  hideSearch
                  longSearch={{ placeholder: "Search by Promotion Name/Id or Product Sku" }}
                  test={this.state.selectedTab}
                  onFilter={(ev) => this.onFilter(ev)}
                ></Filter>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <div>
                  <Tabs
                    id="management-orders-tab-menu"
                    activeKey={this.state.selectedTab}
                    onSelect={(k) => {
                      this.setTabKey(k);
                    }}
                  >
                    <Tab
                      eventKey={PROMOTION_TYPES.UpcomingPromotions}
                      title={`${PROMOTION_TYPES.UpcomingPromotions} Promotions`}
                    ></Tab>
                    <Tab
                      eventKey={PROMOTION_TYPES.ActivePromotions}
                      title={`${PROMOTION_TYPES.ActivePromotions} Promotions`}
                    ></Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            {this.state.loading ? (
              <Loader />
            ) : upcomingPromotions[0]?.Items.length === 0 ? (
              <BlankPage description="There is no upcoming promotion." />
            ) : (
              <div>
                <div className="table-total-count">
                  Total Count: {upcomingPromotions[0]?.TotalCount}
                </div>
                <div className="table-responsive-xl">
                  <table
                    className="table table-centered table-nowrap table-hover mb-0 rounded table-responsive cursor-pointer"
                    id="datatable"
                  >
                    <thead className="thead-light d-short border-0">
                      <tr>
                        <th className="bg-transparent border-0 fmxw-350" >
                          Promotion Name
                        </th>
                        <th className="bg-transparent border-0 ">
                          Publisher Name
                        </th>
                        <th className="bg-transparent border-0 ">
                          Provider Name
                        </th>
                        <th className="bg-transparent border-0 ">Start Date</th>
                        <th className="bg-transparent border-0 ">End Date</th>

                        <th className="bg-transparent border-0 ">
                          Product Count
                        </th>
                        <th className="bg-transparent border-0 ">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {upcomingPromotions[0]?.Items.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr
                              className="rounded-1 mb-1 align-middle border-bottom"
                              key={index}
                            >
                              <td className="fmxw-350">
                                <p>{item.PromotionName}</p>
                              </td>
                              <td>
                                <p>{item.PublisherName}</p>
                              </td>
                              <td>
                                <p>{item.ProviderName}</p>
                              </td>
                              <td>
                                <p>
                                  {moment(item.ValidFrom).format(
                                    defaultDateFormat + "\tH:mm:ss"
                                  )}
                                </p>
                              </td>
                              <td>
                                <p>
                                  {moment(item.ValidTo).format(
                                    defaultDateFormat + "\tH:mm:ss"
                                  )}
                                </p>
                              </td>
                              <td>
                                <p>{item.ProductCount}</p>
                              </td>
                              <td className="ps-0">
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Basic example"
                                >
                                  <Button
                                    className="order-detail-view-detail-button"
                                    bindEvent={() =>


                                      this.setState({
                                        showGenbaPromotionModal: true,
                                        selectedPromotionId: item.PromotionId,
                                      })

                                    }
                                    registerTip="2"
                                    tooltipDesc={`Genba ${this.state.selectedTab} Promotion`}
                                    label=""
                                  >
                                    <img src={Icons.addFromGoogleSheetIcon} />
                                  </Button>

                                  <Button
                                    className="order-detail-view-detail-button"
                                    bindEvent={() =>
                                      this.setState({
                                        showPriceMasterModal: true,
                                        selectedPromotionId: item.PromotionId,
                                      })
                                    }
                                    label=""
                                    registerTip="3"
                                    tooltipDesc="Update Price Master"
                                  >
                                    <img src={Icons.updateGoogleSheetIcon} />
                                  </Button>

                                  <Button
                                    className="order-detail-view-detail-button"
                                    bindEvent={() => {
                                      this.props.onRedirect(
                                        Endpoints.Management.UpcomingProducts.url
                                          .replace(
                                            ":filter?",
                                            base64.encode(
                                              JSON.stringify({
                                                Promotions: [item.PromotionId],
                                                PublishersName: [
                                                  item.PublisherName,
                                                ],
                                                OnlyActivePromotion: "1",
                                                PromotionType:
                                                  this.state.selectedTab,
                                              })
                                            )
                                          )
                                          .replace(":sort?", "0")
                                          .replace(":page?", 0)
                                      );
                                    }}
                                    label=""
                                    registerTip="1"
                                    tooltipDesc="View Detail"
                                  >
                                    <img src={Icons.viewDetailIcon} />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pager
                  pageIndex={this.state.pageindex}
                  totalPages={upcomingPromotions[0]?.TotalPages}
                  totalCount={upcomingPromotions[0]?.TotalCount}
                  onChange={(pageIndex) => this.onChangePageIndex(pageIndex)}
                />
              </div>
            )}
            <MerModal
              id="order-confirm"
              showModal={this.state.showPriceMasterModal}
              ariaLabelledby="contained-modal-title-vcenter"
              onHide={() => this.setState({ showPriceMasterModal: false })}
            >
              <GoogleSheetConfirm
                title="Are you sure want to update Google sheet?"
                handleClose={() => this.setState({ showPriceMasterModal: false })}
                updateGoogleSheet={() => this.updateGoogleSheet()}
                operationType={this.state.selectedTab === PROMOTION_TYPES.ActivePromotions ? OPERATION_TYPES.UpdateGoogleSheetActivePromotion : OPERATION_TYPES.UpdateGoogleSheetUpcomingPromotion}
              ></GoogleSheetConfirm>
            </MerModal>

            <MerModal
              id="order-confirm"
              showModal={this.state.showGenbaPromotionModal}
              ariaLabelledby="contained-modal-title-vcenter"
              onHide={() => this.setState({ showGenbaPromotionModal: false })}
            >
              <GoogleSheetConfirm
                title={`Are you sure you want to update Genba ${this.state.selectedTab} Promotion ?`}
                handleClose={() => this.setState({ showGenbaPromotionModal: false })}
                updateGoogleSheet={() => this.genbaPromotion(this.state.selectedPromotionId)}
                operationType={this.state.selectedTab === PROMOTION_TYPES.ActivePromotions ? OPERATION_TYPES.UpdateActivePromotionSheetCommand : OPERATION_TYPES.UpdateUpcomingPromotionSheetCommand}
              ></GoogleSheetConfirm>
            </MerModal>
          </div>
        )}
      </Content>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingProviderPromotions);
